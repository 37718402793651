<footer class="footer" *ngIf="!getCurrentUrl().includes('/jobboard/get')" [ngClass]="{'footer-login': getCurrentUrl() === '/' || getCurrentUrl().includes('/?redirectpath=')}">
    <div class="container">
        <div class="footer-menu">
            <div class="footer-menu-logo">
                <img src="../../assets/images/unnanu-logo-footer.png" width="27px">
            </div>
            <ul class="footer-menu-links">
                <li><a href="https://unnanu.com/privacy" target="_blank">Privacy</a></li>
                <li><a href="https://unnanu.com/tos" target="_blank">Terms</a></li>
				<li><a href="https://unnanu.com/cookie" target="_blank">Cookie</a></li>
                <li><a href="https://blog.unnanu.com" target="_blank">Blog</a></li>
            </ul>
        </div>
        <div class="footer-content-right">
            <div class="footer-copyright">Copyright {{currentYear}} &copy; Unnanu. All rights reserved.</div>
            <ul class="footer-social-links">
				<li><a href="https://www.linkedin.com/company/unnanu/" target="_blank"
					class="icon-social-linkedin"></a></li>
                <li><a href="https://www.facebook.com/hellounnanu/" target="_blank"
                        class="img-sprite-1 icon-social-facebook"></a></li>
                <!-- <li><a href="https://twitter.com/hellounnanu/" target="_blank"
                        class="img-sprite-1 icon-social-twitter"></a></li> -->
                <li><a href="https://twitter.com/hellounnanu/" target="_blank"><img src="assets/images/xlogo.png" alt="X" class="img-sprite-1 icon-social-twitter"/></a></li>
                <li><a href="mailto:hello@unnanu.ai"><span class="glyphicon glyphicon-envelope"
                            style="font-size: 17px;opacity: 0.2;color: #000;margin-top: 1px;"></span></a></li>
            </ul>
        </div>
    </div>
</footer>
